<template>
  <div :class="classes">
    <div :class="$style.label" v-if="label">
      <span :class="$style.text">{{ label }}</span>
    </div>
    <div :class="$style.subTitle" v-if="subTitle">
      <span :class="$style.text">{{ subTitle }}</span>
    </div>
    <label :class="$style.value">
      <ui-input
        ref="input"
        :class="$style.input"
        :model-value="modelValue"
        :multiline="multiline"
        :placeholder="placeholder"
        :debounce="debounce"
        :required="required"
        :mask="mask"
        :mask-uppercase="maskUppercase"
        :readonly="readonly"
        @update:model-value="onInput"
        @focus="onFocus"
        @blur="onBlur"
      />
      <span :class="$style.actions" v-if="copy">
        <button @click="onCopy">copy</button>
      </span>
    </label>
    <div :class="$style.description" v-if="$slots.description">
      <span :class="$style.text"><slot name="description" /></span>
    </div>
    <div :class="$style.errorContainer" v-if="!hideErrors">
      <transition :name="$style.slideDown">
        <div :class="$style.error" v-if="error">
          <span :class="$style.text">{{ error }}</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<i18n lang="yaml">
ru:
  copied: Скопировано
en:
  copied: Copied
</i18n>

<script>
import UiInput from './input.vue'
import { SIZES } from './consts'
import copyToBuffer from '~/utils/copy-to-buffer'
import { useI18n } from 'vue-i18n'

export default {
  name: 'UiField',
  components: {
    UiInput
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 1
    },
    mask: {
      type: [Boolean, Object, String, Number, RegExp],
      default: false
    },
    maskUppercase: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    debounce: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium',
      validator: v => SIZES.includes(v)
    },
    hideErrors: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      focused: true,
      touched: false
    }
  },
  computed: {
    classes() {
      return [
        this.$style.field,
        this.$style[this.size],
        { [this.$style.filled]: !!this.modelValue },
        { [this.$style.focused]: this.focused },
        { [this.$style.touched]: this.touched },
        { [this.$style.withValue]: this.modelValue },
        { [this.$style.readonly]: this.readonly },
        { [this.$style.withPlaceholder]: this.placeholder }
      ]
    }
  },
  methods: {
    onInput(modelValue) {
      this.touch()
      this.$emit('update:modelValue', modelValue)
    },
    onFocus() {
      this.focused = true
    },
    onBlur() {
      // this.focused = false
      this.touch()
    },
    touch() {
      this.touched = true
    },
    clear() {
      this.touched = false
      this.onInput('')
    },
    setFocus() {
      this.$refs.input.focus()
    },
    onCopy() {
      copyToBuffer(this.modelValue)
      this.$snacks.success(this.t('copied'))
    }
  },
  mounted() {
    if (this.focus) {
      this.setFocus()
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.field {
  position: relative;
}
.input {
  color: #ffffff;
  background: none;
  border: 0 none;
  outline: none;
  width: 100%;
  &::placeholder {
    color: rgba(172, 176, 213, 0.5);
  }
}
.label {
  color: #acb0d5;
  pointer-events: none;
  transform-origin: 0 0;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  .text {
    font-weight: 500;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}
.subTitle {
  .text {
    color: #acb0d5;
  }
}
.value {
  display: flex;
  background: var(--t-form-bg);
}
.description {
  color: #acb0d5;
  font-weight: 400;
  .text {
    a {
      color: #26ff85;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.focused .label,
.filled .label,
.withValue .label,
.withPlaceholder .label {
  transform: translate(0, 0) !important;
}
.readonly .value {
  color: #313132;
}
.actions {
  right: 2.2em;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: inherit;
  padding-left: 1em;
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    color: #72ff9d;
    font-size: 1.3em;
  }
}
.error {
  color: #e53d01;
  text-align: left;
}

.medium {
  &.field {
    padding-bottom: 1.8em;
  }
  .input {
    font-size: 1.6em;
    line-height: 130%;
  }
  .label {
    margin-bottom: 1.05em;
    transform: translate(0, 3.4em);
    .text {
      font-size: 1.3em;
      line-height: 120%;
    }
  }
  .subTitle {
    margin-bottom: 3.2em;
    .text {
      font-size: 1.5em;
      line-height: 130%;
    }
  }
  .value {
    border-radius: 6px;
    padding: 1.8em 2.1em;
    @include down(xl) {
      padding-bottom: 0.8em;
    }
    @include down(sm) {
      padding: 1.9em 1.8em;
    }
  }
  .description {
    margin-top: 0.6em;
    .text {
      font-size: 1.3em;
      line-height: 130%;
      letter-spacing: -0.04em;
    }
  }
  .errorContainer {
    padding: 1.2em 0;
  }
  .error {
    .text {
      font-size: 1.3em;
    }
  }
}

.small {
  &.field {
    padding-bottom: 1.2em;
  }
  .input {
    font-size: 1.6em;
    line-height: 130%;
  }
  .label {
    margin-bottom: 1em;
    transform: translate(0, 2.4em);
    .text {
      font-size: 1.2em;
      line-height: 120%;
    }
  }
  .subTitle {
    margin-bottom: 3.2em;
    .text {
      font-size: 1.5em;
      line-height: 130%;
    }
  }
  .value {
    border-radius: 6px;
    padding: 1.4em 1.2em;
    @include down(xl) {
      padding-bottom: 0.8em;
    }
    @include down(sm) {
      padding: 1.9em 1.8em;
    }
  }
  .description {
    margin-top: 1.2em;
    .text {
      font-size: 1.3em;
      line-height: 130%;
    }
  }
  .errorContainer {
    padding: 1.2em 0;
  }
  .error {
    .text {
      font-size: 1.3em;
    }
  }
}
.slideDown {
  &:global(-leave-active),
  &:global(-enter-active) {
    transition:
      opacity 0.4s ease,
      transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &:global(-enter-from),
  &:global(-leave-to) {
    transform: translate(0, -100%);
    opacity: 0;
  }
}
</style>
